
import AtomButton from '~/components/atoms/common/AtomButton.vue'

export default {
  name: 'AtomLoadingError',
  components: { AtomButton },
  data() {
    return {
    }
  },
  methods: {
    handlePageReload() {
      if (!window) return;
      window.location.reload()
    }
  }
}
