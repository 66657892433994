
export const EnumInputTypes = {
  TEXT: 'text',
  DATE: 'date',
  TEXTAREA: 'textarea',
  NUMBER: 'number',
  PASSWORD: 'password',
  TEL: 'tel'
}

export default {
  name: 'AtomInput',
  props: {
    value: {
      required: false,
      type: String,
      default: ''
    },
    type: {
      required: false,
      type: String,
      default: EnumInputTypes.TEXT
    },
    placeholder: {
      required: false,
      type: String,
      default: 'Input value...'
    },
    name: {
      required: false,
      type: String,
      default: ''
    },
    error: {
      required: false,
      type: String,
      default: ''
    }
  },
  data() {
    return {
      types: EnumInputTypes
      // err: this.error,
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    resetInput() {
      this.inputVal = ''
    },
    focus(opts = {}) {
      if (this.type === this.types.TEXTAREA) {
        this.$refs.textarea.focus(opts)
        return
      }
      return this.$refs.input.focus(opts)
    }
  }
}
