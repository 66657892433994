
export default {
  name: 'AtomFacts',
  props: {
    facts: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    }
  }
}
