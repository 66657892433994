
import lottie from 'lottie-web'

export default {
  name: 'AtomLottieAnimation',
  props: {
    options: {
      type: Object,
      required: true
    },
    height: Number,
    width: Number
  },
  data() {
    return {
      anim: null
    }
  },
  mounted() {
    this.initAnimation()
  },
  watch: {
    options: function () {
      this.initAnimation()
    }
  },
  methods: {
    initAnimation() {
      if (this.anim !== null) this.anim.destroy()
      this.anim = lottie.loadAnimation({
        container: this.$refs.lavContainer,
        renderer: 'svg',
        loop: this.options.loop !== false,
        autoplay: this.options.autoplay !== false,
        animationData: this.options.animationData,
        rendererSettings: this.options.rendererSettings
      })
      this.anim.endFrame = this.options.animationData.endFrame
      this.$emit('animCreated', this.anim)
    }
  }
}
