
export default {
  name: 'AtomLoaderLoadingBar',
  components: {},
  data() {
    return {
      percentage: 0,
      seconds: 30,
      maxPercentage: 90,
      interval: 0,
      closeInterval: 0,
      closeTimeRemaining: 0,
      startedCountdown: false
    }
  },
  mounted() {
    this.interval = setInterval(() => {
      const percentageChange = Math.floor(
        Math.random() * Math.round(this.maxPercentage / this.seconds) + 1
      )
      let percentage = this.percentage
      percentage += percentageChange
      if (percentage > this.maxPercentage) {
        this.percentage = 99
        clearInterval(this.interval)
        this.interval = 0
        // this.startCloseCountdown();
        return
      }
      this.percentage = percentage
    }, 1000)
    this.$store.subscribe((payload, state) => {
      if (state.building.finishedLoadingAssets && this.percentage < 100) {
        clearInterval(this.interval)
        this.interval = 0
        this.percentage = 100
        this.startCloseCountdown()
      }
    })
  },
  beforeDestroy() {
    clearInterval(this.interval)
    clearInterval(this.closeInterval)
  },
  methods: {
    closeLoadingScreen() {
      this.$store.dispatch('building/changeLoadingScreenStatus', false)
      clearInterval(this.interval)
      clearInterval(this.closeInterval)
    },
    startCloseCountdown() {
      this.closeInterval = setInterval(() => {
        this.closeLoadingScreen()
        clearInterval(this.closeInterval)
        this.closeInterval = 0
      }, 1000)
    }
  }
}
